import React, { useState, useEffect } from 'react';
import { PublicClientApplication, InteractionRequiredAuthError, LogLevel,  } from '@azure/msal-browser';
import { useAtom } from 'jotai';
import { accessTokenAtom, graphAccessTokenAtom, isAuthenticatedAtom, accountNameAtom, accountUserNameAtom } from '../Atoms';

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        console.info(message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                }
            },
            piiLoggingEnabled: false,
            logLevel: LogLevel.Verbose,
        }
    }
};


const msalInstance = new PublicClientApplication(msalConfig);

const getTokenLifetime = (token) => {
    // Parse the token to extract the expiration time
    const tokenParts = token.split('.');
    const encodedPayload = tokenParts[1];
    const decodedPayload = atob(encodedPayload);
    const payload = JSON.parse(decodedPayload);

    // Extract expiration time from the payload
    const expirationTimeInSeconds = payload.exp;

    // Convert expiration time to milliseconds and calculate remaining lifetime
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const remainingLifetimeInSeconds = expirationTimeInSeconds - currentTimeInSeconds;

    // Return remaining lifetime in seconds
    return remainingLifetimeInSeconds;
};

const scopes = [
    'profile',
    'User.Read',
    'openid' 
];

const crmScopes = ['https://rfo.crm4.dynamics.com/.default'];

const Auth = () => {
    const [account, setAccount] = useState(null);
    const [graphAccessToken, setGraphAccessToken] = useAtom(graphAccessTokenAtom);
    const [crmAccessToken, setCrmAccessToken] = useAtom(accessTokenAtom);
    const [, setIsAuthenticated] = useAtom(isAuthenticatedAtom)
    const [, setAccountName] = useAtom(accountNameAtom);
    const [, setAccountUserName] = useAtom(accountUserNameAtom);

    useEffect(() => {
        if(graphAccessToken && crmAccessToken){
            setIsAuthenticated(true);
        }
    }, [graphAccessToken, crmAccessToken]);


    useEffect(() => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            // If there's already an account logged in, set it
            setAccount(accounts[0]);
        } else {
            // If no account logged in, trigger login
            login();
        }
    }, []);


    useEffect(() => {
        if (account) {
            getGraphToken();
            getCrmToken();
            setAccountName(account.name);
            setAccountUserName(account.username);
        }
    }, [account]);

    useEffect(() => {
        if (account) {

            // Check token lifetimes and renew if necessary every 5 seconds
            const interval = setInterval(() => {
                renewTokens();
            }, 13000);

            // Renew tokens immediately when this effect is first executed
            renewTokens();

            return () => clearInterval(interval); // Clean up interval on unmount
        }
    }, [account, graphAccessToken, crmAccessToken]);

    const login = async () => {
        try {
            await msalInstance.loginPopup();
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                setAccount(accounts[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const renewTokens = async () => {
        try {
            const graphTokenLifetime = graphAccessToken ? getTokenLifetime(graphAccessToken) : 0;
            const crmTokenLifetime = crmAccessToken ? getTokenLifetime(crmAccessToken) : 0;



            if (graphTokenLifetime <= 0) {
                const accessToken = await acquireTokenWithRetry(scopes);
                setGraphAccessToken(accessToken);
            }

            if (crmTokenLifetime <= 0) {
                const accessToken = await acquireTokenWithRetry(crmScopes);
                setCrmAccessToken(accessToken);
            }
        } catch (error) {
            console.error('Token renewal error:', error);
        }
    };

    const acquireTokenWithRetry = async (scopesArray) => {
        try {
            const response = await msalInstance.acquireTokenSilent({
                scopes: scopesArray,
                account: account,
            });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // If token acquisition fails due to interaction required, attempt interactive login
                try {
                    await msalInstance.loginPopup();
                    const response = await msalInstance.acquireTokenSilent({
                        scopes: scopesArray,
                        account: account,
                    });
                    return response.accessToken;
                } catch (loginError) {
                    console.error('Interactive login error:', loginError);
                    throw loginError;
                }
            } else {
                console.error('Token acquisition error:', error);
                throw error;
            }
        }
    };

    const getGraphToken = async () => {
        try {
            const accessToken = await acquireTokenWithRetry(scopes);
            setGraphAccessToken(accessToken);
        } catch (error) {
            console.error('Graph token error:', error);
        }
    };

    const getCrmToken = async () => {
        try {
            const accessToken = await acquireTokenWithRetry(crmScopes);
            setCrmAccessToken(accessToken);
        } catch (error) {
            console.error('CRM token error:', error);
        }
    };

    return (
        <>
        </>
    );
}

export default Auth;
